import { index } from "./components/index";
import { commons } from "./components/commons";
import { tab } from "./components/tab";
import { Navigation, Pagination, Autoplay, EffectFade, Swiper } from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);
import ScrollHint from 'scroll-hint';


import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger);

import PerfectScrollbar from 'perfect-scrollbar';

index();
commons();
tab('.graduate');
tab('.career');

new ScrollHint('.js-scrollable', {
  scrollHintIconAppendClass: 'scroll-hint-icon-white', // white-icon will appear
  i18n: {
    scrollable: 'スクロールできます'
  }
});


if ($('.organicbeefpage').length) {
  const linkSwiper = new Swiper('.swiper', {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 0,

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      640: {
        loop: false,
        slidesPerView: 3,
        spaceBetween: 35,
        navigation: false
      }
    }

  });
}


if ($('.productspage').length) {
  ScrollTrigger.create({
    trigger: ".footer",
    start: "bottom-=50px bottom",
    endTrigger: ".footer",
    end: "bottom+=200px bottom",
    // markers: true,
    //invalidateOnRefresh: true,
    toggleClass: { targets: ".productspage__contact", className: "is_active" },
    once: false,
  });
}

if ($('.recruitpage').length) {

  var careerps = new PerfectScrollbar('#careertab');
  var graduateps = new PerfectScrollbar('#graduatetab');
}
