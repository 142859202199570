export function tab($baseClass) {
	const tabs = document.querySelectorAll($baseClass + " .tab__link");
	const tab_contents = document.querySelectorAll($baseClass + " .tab__item");
	console.log(tabs);
	console.log(tab_contents);

	const urlHash = location.hash;
	if (urlHash) {
		if (urlHash == '#ict') {
			// console.log(tabs[0]);
			$(".tab__link").removeClass("is__active");
			$(".tab__item").removeClass("is__active");
			$(".tab__link").eq(1).addClass("is__active");
			$(".tab__item").eq(1).addClass("is__active");
		}
	}
	var headH = $("header").outerHeight() + 30;
	var animeSpeed = 500;
	if (urlHash) { //ハッシュタグが有る場合
		$("body,html").scrollTop(0);
		setTimeout(function () { //無くてもいいが有ると動作が安定する
			var target = $(urlHash);
			var position = target.offset().top - headH;
			$("body,html").stop().animate({
				scrollTop: position
			}, animeSpeed);
		}, 0);
	}
	for (let i = 0; i < tabs.length; i++) {
		tabs[i].addEventListener("click", function (e) {
			e.preventDefault();
			console.log(tabs.length);

			for (let j = 0; j < tabs.length; j++) {
				tabs[j].classList.remove("is__active");
			}
			for (let j = 0; j < tabs.length; j++) {
				tab_contents[j].classList.remove("is__active");
			}
			tabs[i].classList.add("is__active");
			tab_contents[i].classList.add("is__active");
		});
	}

}
