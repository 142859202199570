// const moment = require('moment');
// import { gsap } from "gsap";
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
// gsap.registerPlugin(ScrollTrigger);
import { vegas } from "vegas";
import { Navigation, Pagination, Autoplay, EffectFade, Swiper } from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

export function index() {
  if ($('.toppage').length) {
    const mainswiper = new Swiper('.mainswiper', {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 0,
      autoplay: {
        delay: 5000,
      },
      speed: 1500,
      effect: "fade",
      fadeEffect: {
        crossFade: true
      },
    });
  }
}
