
export function commons() {
  $('.nav__open').click(function () {
    $('.nav').addClass('is__active');
  })
  $('.nav__close').click(function () {
    $('.nav').removeClass('is__active');
  })
  $('.anker').click(function () {
    // スクロール速度(ミリ秒)
    var speed = 1000;
    // アンカーの値取得
    var href = $(this).attr("href");
    // 移動先を取得
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    // 移動
    $('body,html').animate({ scrollTop: position }, speed, 'swing');
    return false;
  });
}
